export default function Model({ children, show, onClose }) {
    return (
        <>
            <div id="defaultModal" aria-hidden="true" className={`${show ? "visible" : "hidden"} fixed z-5 inset-0 z-40 story-model`}>
                <div className="relative shadow bg-black h-screen w-screen">
                    <div className="absolute right-10 top-4 hidden md:block">
                        <button type="button" className="text-gray-500 z-50 curcursor-pointer bg-transparent hover:bg-white hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal" onClick={onClose}>
                            <svg className="w-8 h-8 z-50 curcursor-pointer" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}