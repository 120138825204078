import React, { useEffect, useState, Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import { FcPrevious, FcNext } from 'react-icons/fc';

import Spinner from '../spinner';
import StoryCard from './StoryCard';
import Model from '../layout/Modal';

const AMP_CONFIG = {
    "controls": [
        {
            "name": "close",
            "position": "end"
        }
    ],
    "behavior": {
        "on": "end",
        "action": "circular-wrapping"
    },
    "display": {
        "attribution": "auto"
    }
}

const AMP_STORY_PLAYER_URL = "https://cdn.ampproject.org/amp-story-player-v0";

const Stories = ({ isLoading, stories, fetchMoreData, page, totalPage }) => {

    const [showStoryPlayer, setShowStoryPlayer] = useState(false);
    
    function getAmpScript(callback) {
        const ampJS = document.createElement("script");
        ampJS.async = true;
        ampJS.src = `${AMP_STORY_PLAYER_URL}.js`;
        ampJS.onload = callback;
        return ampJS;
    }

    function getAmpCss() {
        const ampCSS = document.createElement("link");
        ampCSS.href = `${AMP_STORY_PLAYER_URL}.css`;
        ampCSS.rel = "stylesheet";
        ampCSS.type = "text/css";

        return ampCSS;
    }

    const removeAmpScript = () => {
        const ampScript = document.querySelector(`script[src="${AMP_STORY_PLAYER_URL}.js"]`);;
        const cleverpushStoryContainer = document.querySelector("#cleverpush-story-player");
        const ampPlayer = document.querySelector('amp-story-player');

        if (ampScript && ampPlayer) {
            const ampJs = document.querySelector(`script[src="${AMP_STORY_PLAYER_URL}.js"]`);;
            const ampCss = document.querySelector(`link[href="${AMP_STORY_PLAYER_URL}.css"]`);
            document.head.removeChild(ampJs);
            document.head.removeChild(ampCss);
            cleverpushStoryContainer.removeChild(ampPlayer)
        }
    }

    const getAmpStoryPlayer = () => {
        const cleverpushStoryContainer = document.querySelector("#cleverpush-story-player");

        if (!cleverpushStoryContainer) {
            return;
        }

        const ampPlayer = document.createElement('amp-story-player');
        ampPlayer.setAttribute('amp-cache', 'cdn.ampproject.org');

        const controllerScript = document.createElement("script");
        controllerScript.type = 'application/json';
        controllerScript.innerHTML = JSON.stringify(AMP_CONFIG);
        ampPlayer.appendChild(controllerScript);

        (stories || []).forEach(story => {
            const anchorTag = document.createElement('a');
            anchorTag.setAttribute('href', generateApiEndpoint(story));
            anchorTag.setAttribute('target', '_blank');

            ampPlayer.appendChild(anchorTag);
        });

        cleverpushStoryContainer.appendChild(ampPlayer);
    }

    const injectAmpScript = () => {
        document.head.appendChild(getAmpScript());
        document.head.appendChild(getAmpCss());
        getAmpStoryPlayer();
    }

    useEffect(() => {
        const ampScript = document.querySelector(`script[src="${AMP_STORY_PLAYER_URL}.js"]`);
        if (!ampScript) {
            injectAmpScript()
        } else {
            injectAmpScript();
        }
        return () => {
            removeAmpScript()
        }
    }, [page]);



    const playerDo = (callback) => {
        const player = document.querySelector('amp-story-player');
        if (player.isReady) {
            callback(player);
            initializeClickListeners(player);
        } else {
            player.addEventListener("ready", () => {
                callback(player);
            });
            initializeClickListeners(player);
        }
    };

    const initializeClickListeners = (player) => {
        player.addEventListener('amp-story-player-close', () => {
            onModelClose(player);
        });
    }

    const onModelClose = () => {
        const player = document.querySelector('amp-story-player');
        if (player && player.isReady) {
            player.mute();
        }
        setShowStoryPlayer(false);
    }

    const generateApiEndpoint = (story) => {
        return story.content && story.content.canonicalUrl
    }

    return (
        <Fragment>
            <div className='container mx-auto px-4 my-5'>
                {
                    isLoading ? <Spinner /> :
                        <Fragment>
                            <Model show={showStoryPlayer} onClose={onModelClose}>
                                <div className='story-player-container' id="cleverpush-story-player" />
                            </Model>
                            <div className="flex flex-wrap -mx-1 lg:-mx-4 items-center  my-5 overflow-hidden">
                                {stories.map((story, index) => <StoryCard key={story._id} story={story} index={index} handleStoryPlayer={playerDo} onShow={() => setShowStoryPlayer(true)} generateApiEndpoint={generateApiEndpoint} />)}
                            </div>
                            <div className='relative z-5 text-center'>
                                <ReactPaginate
                                    onPageChange={(page) => {
                                        fetchMoreData(page?.selected)
                                    }}
                                    previousLabel={<FcPrevious className='h-4  w-4 flex justify-center items-center' />}
                                    nextLabel={<FcNext className='h-4 w-4 flex justify-center items-center' />}
                                    breakLabel="…"
                                    pageRangeDisplayed={1}
                                    disabledClassName='page-disabled'
                                    forcePage={parseInt(page || 0, 10)}
                                    pageCount={totalPage || 0}
                                    breakClassName="border-gray-300 relative inline-flex items-center px-4 py-3 border text-sm font-medium cursor-pointer break"
                                    pageLinkClassName="border-grey-300 relative inline-flex px-4 py-3 border text-sm font-medium cursor-pointer pages-class"
                                    previousClassName="relative leading-tight hover:bg-gray-100 inline-flex items-center px-4 py-3 border text-sm font-medium cursor-pointer"
                                    nextLinkClassName="relative inline-flex hover:bg-gray-100 items-center px-4 py-3 border text-sm font-medium cursor-pointer custom-next"
                                    containerClassName="inline-flex -space-x-px cleverpush-story-pagination bg-white"
                                    activeClassName="relative text-white items-center text-sm font-large active-page"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default Stories