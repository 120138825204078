import React, { Fragment } from 'react';
import { BsFillPlayFill } from 'react-icons/bs';

const showStoryImage = (story) => {
    return story?.content?.preview?.posterPortraitSrc || story?.imageUrls?.[0] || ''
}

const StoryCard = ({ story, handleStoryPlayer, onShow, generateApiEndpoint }) => {
    return (
        <Fragment>
            <div className='overflow-hidden h-80 w-60 rounded-2xl cursor-pointer my-1 px-1 md:w-1/2  lg:my-5 lg:px-4 lg:w-1/5  hover:border-5 grid-item' data-aos="fade-up"
                data-aos-anchor-placement="top-bottom" onClick={() => {
                    handleStoryPlayer((player) => {
                        onShow();
                        const url = generateApiEndpoint(story);
                        if (url) {
                            player.show(url);
                            player.play();
                            player.unmute();
                        }
                    })
                }}>
                <div className='absolute overflow-hidden mx-auto bg-white rounded-2xl item-card'>
                    <div className=' h-full rounded-2xl block relative overflow-hidden card-innner'>
                        <div className='h-full w-full overflow-hidden relative card-image'>
                            <img className="object-cover w-full h-full absolute rounded-2xl" src={showStoryImage(story)} loading='lazy' />
                            <div className="absolute h-full w-full inset-0  z-10 img-overlay"></div>
                            <div className='absolute  flex justify-center px-4  text-center w-full h-10 text-base items-center opacity-0 text-white text-red  overlay-icon'>
                                <BsFillPlayFill className='text-white text-4xl h-11 w-11' />
                            </div>
                            <div className="absolute bottom-0 top-0  w-full overflow-auto flex card-text">
                                <span className="mb-3 leading-7 text-xl font-semibold overflow-hidden text-over text-ellipsis tracking-tight text-white story-title">{story.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default StoryCard;