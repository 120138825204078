import React from 'react';

const Header = ({ channel }) => {
    return (
        <nav className="flex items-center justify-between flex-wrap bg-white p-6 shadow-xl">
            <div className="flex items-center flex-shrink-0 text-black mr-6">
                {channel && channel.iconUrl ? <img className="inline object-cover w-12 h-12 mr-3 rounded-full border-2" src={channel.iconUrl} alt="Channel Icon" /> : null}
                <span className="font-semibold text-xl tracking-tight">{(channel && channel.name)}</span>
            </div>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            </div>
        </nav>
    )
}

export default Header;