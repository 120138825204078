import { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Aos from 'aos';
import { Helmet } from "react-helmet";

import Header from './components/layout/Header';
import Stories from './components/stories';

const PAGE_SIZE = 10;

const App = () => {

  const [state, setState] = useState({
    stories: [],
    channelData: {},
    isLoading: false,
    hasError: false,
  });

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const getStories = async (page) => {
    try {
      const response = await axios.get(`${window.apiEndpoint}/channel/${window.channelId}/stories-public?limit=${PAGE_SIZE}&skip=${page * PAGE_SIZE}`);
      const data = await response.data;
      const totalPage = Math.ceil(data.totalCount / PAGE_SIZE)
      setTotalPage(totalPage)
      return data || [];
    } catch (error) {
      setState({ ...state, hasError: true })
    }
  }

  useEffect(() => {
    Aos.init({
      duration: 1000
    })
    fetchMoreData(page)
  }, []);

  const fetchMoreData = (page) => {
    setState({ ...state, isLoading: true })
    getStories(page).then((res) => {
      setState({ ...state, stories: res.stories, channelData: res.channel, isLoading: false });
      setPage(page);
    }).catch(() => {
      setState({ ...state, hasError: true, isLoading: false })
    })
  };

  if (state.hasError) {
    return <div className='text-center mt-3 text-xl text-red-500'>Something went wrong ...</div>
  }

  return (
    <Fragment>
      <Helmet title={`${state?.channelData?.name || ""} Stories`} link={[{ "rel": "icon", "type": "image/png", "href": `${state?.channelData?.iconUrl}` }]} />
      <Header channel={state.channelData} />
      {state.stories && state.stories.length > 0 ? <Stories stories={state.stories || []} isLoading={state.isLoading} fetchMoreData={fetchMoreData} getStories={getStories} page={page} totalPage={totalPage} /> : null}
    </Fragment>
  );
}

export default App;
